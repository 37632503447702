var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.onclickCrear
    }
  }, [_vm._v(" Crear ")]), _c('b-modal', {
    attrs: {
      "id": "modal-crear-promo",
      "size": "lg",
      "title": _vm.modalTitle,
      "centered": "",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "loader"
  }, [_c('b-spinner')], 1) : _c('form-wizard', {
    staticClass: "wizard-vertical",
    attrs: {
      "color": "#04a82c",
      "layout": "vertical",
      "title": null,
      "subtitle": null,
      "back-button-text": "Volver",
      "next-button-text": "Siguiente",
      "finish-button-text": "Crear promocode"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Datos Generales",
      "before-change": function beforeChange() {
        return _vm.validateDatosGenerales();
      }
    }
  }, [_c('h4', [_vm._v("Datos Generales")]), _c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "v-name"
    }
  }, [_c('b-form-input', {
    class: [_vm.error.DatosGenerales.nombre ? 'is-invalid' : ''],
    attrs: {
      "id": "v-name",
      "placeholder": "Nombre"
    },
    model: {
      value: _vm.promocion.PrNombre,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrNombre", $$v);
      },
      expression: "promocion.PrNombre"
    }
  }), _vm.error.DatosGenerales.nombre ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingresar un nombre ")]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Descripción",
      "label-for": "v-descripcion"
    }
  }, [_c('b-form-input', {
    class: [_vm.error.DatosGenerales.descripcion ? 'is-invalid' : ''],
    attrs: {
      "id": "v-descripcion",
      "placeholder": "Descripción"
    },
    model: {
      value: _vm.promocion.PrDescripcion,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrDescripcion", $$v);
      },
      expression: "promocion.PrDescripcion"
    }
  }), _vm.error.DatosGenerales.descripcion ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingresar una descripción ")]) : _vm._e()], 1), _vm.isPromocode ? _c('b-form-group', {
    attrs: {
      "label": "Código",
      "label-for": "v-codigo"
    }
  }, [_c('b-form-input', {
    class: [_vm.error.DatosGenerales.codigo ? 'is-invalid' : ''],
    attrs: {
      "id": "v-codigo",
      "placeholder": "Código"
    },
    on: {
      "input": function input(v) {
        _vm.promocion.PrCodigo = v.toUpperCase();
      }
    },
    model: {
      value: _vm.promocion.PrCodigo,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrCodigo", $$v);
      },
      expression: "promocion.PrCodigo"
    }
  }), _vm.error.DatosGenerales.codigo ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingresar una codigo ")]) : _vm._e()], 1) : _vm._e(), !_vm.isPromocode ? _c('b-form-group', {
    staticClass: "mr-1",
    attrs: {
      "label": "Seleccionar todos los bancos",
      "label-for": "vi-modo-prueba"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-modo-prueba1",
      "switch": "",
      "checked": "promocion.AllBancos"
    },
    on: {
      "change": _vm.onchangeAllBancos
    },
    model: {
      value: _vm.promocion.AllBancos,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "AllBancos", $$v);
      },
      expression: "promocion.AllBancos"
    }
  })], 1) : _vm._e(), !_vm.isPromocode && !_vm.promocion.AllBancos ? _c('b-form-group', {
    attrs: {
      "label": "Banco",
      "label-for": "v-banco"
    }
  }, [_c('v-select', {
    class: [_vm.error.DatosGenerales.idBanco ? 'is-invalid' : ''],
    attrs: {
      "id": "v-banco",
      "label": "title",
      "reduce": function reduce(b) {
        return b.title;
      },
      "options": _vm.bancos,
      "clearable": false
    },
    on: {
      "option:selected": _vm.onchangeBanco
    },
    model: {
      value: _vm.promocion.PrIdbanco,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrIdbanco", $$v);
      },
      expression: "promocion.PrIdbanco"
    }
  }), _vm.error.DatosGenerales.idBanco ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Seleccione un banco ")]) : _vm._e()], 1) : _vm._e(), !_vm.isPromocode && _vm.promocion.PrIdbanco && !_vm.promocion.AllBancos ? _c('b-form-group', {
    staticClass: "mr-1",
    attrs: {
      "label": "Seleccionar todos Bines",
      "label-for": "vi-modo-prueba"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-modo-prueba",
      "switch": "",
      "checked": "promocion.AllBines"
    },
    on: {
      "change": _vm.onchangeAllBines
    },
    model: {
      value: _vm.promocion.AllBines,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "AllBines", $$v);
      },
      expression: "promocion.AllBines"
    }
  })], 1) : _vm._e(), !_vm.isPromocode && _vm.promocion.PrIdbanco && !_vm.promocion.AllBines && !_vm.promocion.AllBancos ? _c('b-form-group', {
    attrs: {
      "label": "Tarjeta",
      "label-for": "v-tarjeta"
    }
  }, [_c('v-select', {
    class: [_vm.error.DatosGenerales.idTarjeta ? 'is-invalid' : ''],
    attrs: {
      "id": "v-tarjeta",
      "label": "title",
      "reduce": function reduce(t) {
        return t.title;
      },
      "options": _vm.tarjetas,
      "clearable": false
    },
    on: {
      "option:selected": _vm.onchangeTarjeta
    },
    model: {
      value: _vm.promocion.PrIdtarjeta,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrIdtarjeta", $$v);
      },
      expression: "promocion.PrIdtarjeta"
    }
  }), _vm.error.DatosGenerales.idTarjeta ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Seleccione una trajeta ")]) : _vm._e()], 1) : _vm._e(), !_vm.isPromocode && _vm.promocion.PrIdtarjeta && !_vm.promocion.AllBancos ? _c('b-form-group', {
    attrs: {
      "label": "Bin",
      "label-for": "v-bin"
    }
  }, [_c('v-select', {
    class: [_vm.error.DatosGenerales.bines ? 'is-invalid' : ''],
    attrs: {
      "id": "v-bin",
      "label": "biBin",
      "reduce": function reduce(b) {
        return b.biIdbines;
      },
      "options": _vm.mBines,
      "multiple": ""
    },
    model: {
      value: _vm.promocion.Bines,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "Bines", $$v);
      },
      expression: "promocion.Bines"
    }
  }), _vm.error.DatosGenerales.bines ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Seleccione Bin ")]) : _vm._e()], 1) : _vm._e()], 1), _c('tab-content', {
    attrs: {
      "title": "Fecha de validez",
      "before-change": function beforeChange() {
        return _vm.validateFechaValidez();
      }
    }
  }, [_c('h4', [_vm._v("Fecha de validez")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha de Inicio",
      "label-for": "v-fecha-ini"
    }
  }, [_c('b-form-datepicker', {
    class: [_vm.error.fechaValidez.fechaInicio ? 'is-invalid' : ''],
    attrs: {
      "id": "v-fecha-ini",
      "min": _vm.currentDate,
      "locale": "es-EC",
      "date-format-options": {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }
    },
    model: {
      value: _vm.promocion.PrFechaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrFechaInicio", $$v);
      },
      expression: "promocion.PrFechaInicio"
    }
  }), _vm.error.fechaValidez.fechaInicio ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingresar una fecha de inicio ")]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora de Inicio",
      "label-for": "v-hora-ini"
    }
  }, [_c('b-form-input', {
    class: [_vm.error.fechaValidez.HoraInicio ? 'is-invalid' : ''],
    attrs: {
      "type": "time",
      "id": "v-hora-ini",
      "locale": "en"
    },
    model: {
      value: _vm.promocion.PrHoraInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrHoraInicio", $$v);
      },
      expression: "promocion.PrHoraInicio"
    }
  }), _vm.error.fechaValidez.HoraInicio ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingresar una hora de inicio ")]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha de fin",
      "label-for": "v-fecha-fin"
    }
  }, [_c('b-form-datepicker', {
    class: [_vm.error.fechaValidez.fechaFin ? 'is-invalid' : ''],
    attrs: {
      "id": "v-fecha-fin",
      "min": _vm.promocion.PrFechaInicio,
      "date-format-options": {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      },
      "locale": "es-EC"
    },
    model: {
      value: _vm.promocion.PrFechaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrFechaFin", $$v);
      },
      expression: "promocion.PrFechaFin"
    }
  }), _vm.error.fechaValidez.fechaFin ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingresar una fecha de finalización ")]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora de fin",
      "label-for": "v-hora-fin"
    }
  }, [_c('b-form-input', {
    class: [_vm.error.fechaValidez.horaFin ? 'is-invalid' : ''],
    attrs: {
      "type": "time",
      "id": "v-hora-fin",
      "locale": "en"
    },
    model: {
      value: _vm.promocion.PrHoraFin,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrHoraFin", $$v);
      },
      expression: "promocion.PrHoraFin"
    }
  }), _vm.error.fechaValidez.horaFin ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Debe ingresar una hora de finalización ")]) : _vm._e()], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Tipo",
      "before-change": function beforeChange() {
        return _vm.validateTipo();
      }
    }
  }, [_c('h4', [_vm._v("Tipo")]), _c('b-form-group', [_c('b-form-radio-group', {
    attrs: {
      "id": "v-tipo",
      "buttons": "",
      "options": _vm.tipos,
      "button-variant": "outline-primary"
    },
    on: {
      "change": _vm.onchangeTipo
    },
    model: {
      value: _vm.promocion.PrTipoPromocion,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrTipoPromocion", $$v);
      },
      expression: "promocion.PrTipoPromocion"
    }
  })], 1), _vm.promocion.PrTipoPromocion !== 6 ? _c('b-form-group', {
    attrs: {
      "label": _vm.promocion.PrTipoPromocion === 3 ? 'Porcentaje' : 'Monto',
      "label-for": "v-valor"
    }
  }, [_c('b-input-group', {
    attrs: {
      "prepend": _vm.promocion.PrTipoPromocion === 3 ? '%' : '$'
    }
  }, [_c('b-form-input', {
    class: [_vm.error.tipo.porcentajeDescuento ? 'is-invalid' : ''],
    attrs: {
      "id": "v-valor",
      "type": "number",
      "placeholder": _vm.promocion.PrTipoPromocion === 3 ? 'Porcentaje' : 'Monto'
    },
    model: {
      value: _vm.promocion.PrPorcentajeDescuento,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrPorcentajeDescuento", $$v);
      },
      expression: "promocion.PrPorcentajeDescuento"
    }
  }), _vm.error.tipo.porcentajeDescuento ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Ingrese descuento ")]) : _vm._e()], 1)], 1) : _vm._e()], 1), _c('tab-content', {
    attrs: {
      "title": "Cantidad de usos",
      "before-change": function beforeChange() {
        return _vm.validateCantidadUsos();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', [_vm._v("Cantidad de usos")])]), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cantidad máxima de usos",
      "label-for": "v-cant-max"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UsersIcon"
    }
  })], 1), _c('b-form-input', {
    class: [_vm.error.cantidadUsos.CantidadMaximaPromocion ? 'is-invalid' : ''],
    attrs: {
      "id": "v-cant-max",
      "type": "number",
      "step": "1",
      "formatter": function formatter(v) {
        return "".concat(parseInt(v));
      },
      "placeholder": "0 = ilimitado"
    },
    model: {
      value: _vm.promocion.PrCantidadMaximaPromocion,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrCantidadMaximaPromocion", $$v);
      },
      expression: "promocion.PrCantidadMaximaPromocion"
    }
  }), _vm.error.cantidadUsos.CantidadMaximaPromocion ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Ingrese descuento ")]) : _vm._e()], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cantidad máxima de uso por Cliente",
      "label-for": "v-cant-cliente"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon"
    }
  })], 1), _c('b-form-input', {
    class: [_vm.error.cantidadUsos.CantidadUsoUsuario ? 'is-invalid' : ''],
    attrs: {
      "id": "v-cant-cliente",
      "type": "number",
      "step": "1",
      "formatter": function formatter(v) {
        return "".concat(parseInt(v));
      },
      "placeholder": "0 = ilimitado"
    },
    model: {
      value: _vm.promocion.PrCantidadUsoUsuario,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrCantidadUsoUsuario", $$v);
      },
      expression: "promocion.PrCantidadUsoUsuario"
    }
  }), _vm.error.cantidadUsos.CantidadUsoUsuario ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Ingrese descuento ")]) : _vm._e()], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Monto Mínimo Compra",
      "label-for": "v-valor-min"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon"
    }
  })], 1), _c('b-form-input', {
    class: [_vm.error.cantidadUsos.MontoMinimoCompra ? 'is-invalid' : ''],
    attrs: {
      "id": "v-valor-min",
      "type": "number",
      "step": ".01",
      "value": _vm.maskedValue(_vm.promocion, 'PrMontoMinimoCompra'),
      "placeholder": ""
    },
    model: {
      value: _vm.promocion.PrMontoMinimoCompra,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrMontoMinimoCompra", $$v);
      },
      expression: "promocion.PrMontoMinimoCompra"
    }
  }), _vm.error.cantidadUsos.MontoMinimoCompra ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Ingrese descuento ")]) : _vm._e()], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Monto Máximo Compra",
      "label-for": "v-valor-max"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon"
    }
  })], 1), _c('b-form-input', {
    class: [_vm.error.cantidadUsos.MontoMaximoCompra ? 'is-invalid' : ''],
    attrs: {
      "id": "v-valor-max",
      "type": "number",
      "step": ".01",
      "value": _vm.maskedValue(_vm.promocion, 'PrMontoMaximoCompra'),
      "placeholder": ""
    },
    model: {
      value: _vm.promocion.PrMontoMaximoCompra,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrMontoMaximoCompra", $$v);
      },
      expression: "promocion.PrMontoMaximoCompra"
    }
  }), _vm.error.cantidadUsos.MontoMaximoCompra ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Ingrese descuento ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Condiciones",
      "before-change": function beforeChange() {
        return _vm.validateCondiciones();
      }
    }
  }, [_c('h4', [_vm._v("Condiciones")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Comercio",
      "label-for": "v-comercio"
    }
  }, [_c('b-form-checkbox-group', {
    attrs: {
      "id": "v-comercio",
      "buttons": "",
      "options": _vm.comercios,
      "button-variant": "outline-primary"
    },
    on: {
      "change": _vm.onChangeComercio
    },
    model: {
      value: _vm.promocion.Comercios,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "Comercios", $$v);
      },
      expression: "promocion.Comercios"
    }
  })], 1)], 1), _vm.showStores ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h6', [_vm._v("Tiendas disponibles")]), _c('b-input-group', {
    staticClass: "input-group-merge mb-1"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "text-muted",
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "size": "sm",
      "placeholder": "Buscar..."
    },
    on: {
      "input": _vm.filteredStores
    },
    model: {
      value: _vm.searchStore,
      callback: function callback($$v) {
        _vm.searchStore = $$v;
      },
      expression: "searchStore"
    }
  })], 1), _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('draggable', {
    staticClass: "mb-1 cursor-move drgcnt",
    attrs: {
      "list": _vm.unAssigned,
      "group": "people"
    }
  }, _vm._l(_vm.unAssigned, function (store, idx) {
    return _c('div', {
      key: store.id
    }, [_c('b-badge', {
      staticClass: "mr-25 mb-25",
      attrs: {
        "variant": "light-dark"
      }
    }, [_c('small', [_vm._v(_vm._s(store.razonComercial))])])], 1);
  }), 0)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h6', {
    staticClass: "text-primary"
  }, [_vm._v("Tiendas seleccionadas")]), _c('b-input-group', {
    staticClass: "input-group-merge mb-1"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "text-muted",
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "size": "sm",
      "placeholder": "Buscar..."
    },
    on: {
      "input": _vm.filteredStoresSelected
    },
    model: {
      value: _vm.searchStoreSelected,
      callback: function callback($$v) {
        _vm.searchStoreSelected = $$v;
      },
      expression: "searchStoreSelected"
    }
  })], 1), _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('draggable', {
    staticClass: "mb-1 cursor-move drgcnt",
    attrs: {
      "list": _vm.assigned,
      "group": "people",
      "item-key": "id"
    },
    on: {
      "change": _vm.onChangeAssigned
    }
  }, _vm._l(_vm.assigned, function (store, idx) {
    return _c('div', {
      key: store.id
    }, [_c('b-badge', {
      staticClass: "mr-25 mb-25",
      attrs: {
        "variant": "light-primary"
      }
    }, [_c('small', [_vm._v(_vm._s(store.razonComercial))])])], 1);
  }), 0)], 1)], 1)], 1)], 1) : _vm._e(), _vm.showStores ? _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-button', {
    staticStyle: {
      "font-size": "10px"
    },
    attrs: {
      "size": "sm",
      "block": "",
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.unselectAllStores
    }
  }, [_vm._v(" Quitar todo ")])], 1) : _vm._e(), _vm.showStores ? _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-button', {
    staticStyle: {
      "font-size": "10px"
    },
    attrs: {
      "size": "sm",
      "block": "",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.selectAllStores
    }
  }, [_vm._v(" Seleccionar todo ")])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Valido para",
      "label-for": "v-validez"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "v-validez",
      "buttons": "",
      "options": _vm.validos,
      "button-variant": "outline-primary"
    },
    model: {
      value: _vm.promocion.PrClienteNuevo,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrClienteNuevo", $$v);
      },
      expression: "promocion.PrClienteNuevo"
    }
  })], 1)], 1), _vm.promocion.PrTipoPromocion !== 6 ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Free delivery",
      "label-for": "v-free-d"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "v-free-d",
      "switch": ""
    },
    model: {
      value: _vm.promocion.PrEnvioGratis,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "PrEnvioGratis", $$v);
      },
      expression: "promocion.PrEnvioGratis"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Resumen"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.tipoPromo === "PROCO" ? "Promocode:" : "Promotarjeta:") + " "), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(_vm._s(_vm.promocion.PrCodigo))]), _vm.promocion.PrTipoPromocion !== 6 ? _c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "pill": "",
      "variant": "light-dark"
    }
  }, [_vm._v(_vm._s(_vm.promocion.PrTipoPromocion === 3 ? "%" : "$") + " " + _vm._s(_vm.promocion.PrPorcentajeDescuento))]) : _vm._e(), _vm.promocion.PrEnvioGratis || _vm.promocion.PrTipoPromocion === 6 ? _c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "pill": "",
      "variant": "light-info"
    }
  }, [_vm._v("FREE DELIVERY")]) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "TagIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Nombre de la promoción")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(_vm._s(_vm.promocion.PrNombre))])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "FileTextIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Descripción")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(_vm._s(_vm.promocion.PrDescripcion))])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Fecha de Inicio")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.formattedDate(_vm.promocion.PrFechaInicio)) + " ")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Fecha de Fin")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.formattedDate(_vm.promocion.PrFechaFin)) + " ")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ClockIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Hora de Inicio")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.formattedHour(_vm.promocion.PrHoraInicio)) + " ")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ClockIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Hora de fin")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.formattedHour(_vm.promocion.PrHoraFin)) + " ")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UsersIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Cantidad Máxima de uso")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(_vm._s(_vm.promocion.PrCantidadMaximaPromocion))])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Cantidad de uso por cliente")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(_vm._s(_vm.promocion.PrCantidadUsoUsuario))])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Monto mínimo de compra")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(_vm._s(_vm.promocion.PrMontoMinimoCompra))])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Monto máximo de compra")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(_vm._s(_vm.promocion.PrMontoMaximoCompra))])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ShoppingCartIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Comercios")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.promocion.Comercios.map(function (a) {
    return _vm.nombreCatalogo(a);
  }).join(",")) + " ")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "TargetIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Promoción valida para")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(" " + _vm._s(_vm.promocion.PrClienteNuevo ? "Clientes nuevos" : "Todos los clientes") + " ")])])], 1)], 1), _vm.tipoPromo === 'PROTA' ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "TagIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Banco")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(_vm._s(_vm.promocion.PrIdbanco))])])], 1)], 1) : _vm._e(), _vm.tipoPromo === 'PROTA' ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-secondary",
      "size": "30"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "TagIcon",
      "size": "15"
    }
  })], 1)], 1), _c('b-media-body', [_c('p', {
    staticClass: "mht"
  }, [_vm._v("Tarjeta")]), _c('p', {
    staticClass: "mhj"
  }, [_vm._v(_vm._s(_vm.promocion.PrIdtarjeta))])])], 1)], 1) : _vm._e(), _vm.tipoPromo === 'PROTA' ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('app-collapse', {
    attrs: {
      "accordion": "",
      "type": "border"
    }
  }, [_c('app-collapse-item', {
    staticClass: "store-cont",
    attrs: {
      "title": "Bines",
      "isVisible": ""
    }
  }, _vm._l(_vm.promocion.Bines, function (bin, idx) {
    return _c('b-badge', {
      key: idx,
      attrs: {
        "pill": "",
        "variant": "light-dark"
      }
    }, [_vm._v(_vm._s(bin) + " ")]);
  }), 1)], 1)], 1) : _vm._e(), _vm.showStores ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('app-collapse', {
    attrs: {
      "accordion": "",
      "type": "border"
    }
  }, [_c('app-collapse-item', {
    staticClass: "store-cont",
    attrs: {
      "title": "Tiendas"
    }
  }, _vm._l(_vm.assigned, function (store) {
    return _c('b-badge', {
      key: store.idEmpresaAsociado,
      attrs: {
        "pill": "",
        "variant": "light-dark"
      }
    }, [_vm._v(_vm._s(store.razonComercial))]);
  }), 1)], 1)], 1) : _vm._e()], 1), _c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "mt-3 text-right",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.promocion.prActivo,
      callback: function callback($$v) {
        _vm.$set(_vm.promocion, "prActivo", $$v);
      },
      expression: "promocion.prActivo"
    }
  }, [_vm._v(" Activar promoción ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }