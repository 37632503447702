<template>
  <div>
    <b-button @click="onclickCrear" variant="primary"> Crear </b-button>
    <b-modal
      id="modal-crear-promo"
      size="lg"
      :title="modalTitle"
      v-model="show"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <div v-if="loading" class="loader">
        <b-spinner />
      </div>
      <form-wizard
        v-else
        color="#04a82c"
        class="wizard-vertical"
        layout="vertical"
        :title="null"
        :subtitle="null"
        @on-complete="formSubmitted"
        back-button-text="Volver"
        next-button-text="Siguiente"
        finish-button-text="Crear promocode"
      >
        <tab-content title="Datos Generales" :before-change="() => validateDatosGenerales()">
          <h4>Datos Generales</h4>
          <b-form-group label="Nombre" label-for="v-name">
            <b-form-input
              id="v-name"
              v-model="promocion.PrNombre"
              :class="[error.DatosGenerales.nombre ? 'is-invalid' : '']"
              placeholder="Nombre"
            />
            <div v-if="error.DatosGenerales.nombre" class="invalid-feedback">
              Debe ingresar un nombre
            </div>
          </b-form-group>
          <b-form-group label="Descripción" label-for="v-descripcion">
            <b-form-input
              id="v-descripcion"
              v-model="promocion.PrDescripcion"
              :class="[error.DatosGenerales.descripcion ? 'is-invalid' : '']"
              placeholder="Descripción"
            />
            <div v-if="error.DatosGenerales.descripcion" class="invalid-feedback">
              Debe ingresar una descripción
            </div>
          </b-form-group>
          <b-form-group v-if="isPromocode" label="Código" label-for="v-codigo">
            <b-form-input
              id="v-codigo"
              v-model="promocion.PrCodigo"
              @input="
                (v) => {
                  promocion.PrCodigo = v.toUpperCase();
                }
              "
              :class="[error.DatosGenerales.codigo ? 'is-invalid' : '']"
              placeholder="Código"
            />
            <div v-if="error.DatosGenerales.codigo" class="invalid-feedback">
              Debe ingresar una codigo
            </div>
          </b-form-group>
          <b-form-group
            v-if="!isPromocode"
            label="Seleccionar todos los bancos"
            class="mr-1"
            label-for="vi-modo-prueba"
          >
            <b-form-checkbox
              id="vi-modo-prueba1"
              class="custom-control-primary"
              switch
              @change="onchangeAllBancos"
              checked="promocion.AllBancos"
              v-model="promocion.AllBancos"
            />
          </b-form-group>
          <b-form-group v-if="!isPromocode && !promocion.AllBancos" label="Banco" label-for="v-banco">
            <v-select
              id="v-banco"
              label="title"
              v-model="promocion.PrIdbanco"
              :reduce="(b) => b.title"
              :options="bancos"
              :clearable="false"
              @option:selected="onchangeBanco"
              :class="[error.DatosGenerales.idBanco ? 'is-invalid' : '']"
            />
            <div v-if="error.DatosGenerales.idBanco" class="invalid-feedback">
              Seleccione un banco
            </div>
          </b-form-group>

          <b-form-group
            v-if="!isPromocode && promocion.PrIdbanco && !promocion.AllBancos"
            label="Seleccionar todos Bines"
            class="mr-1"
            label-for="vi-modo-prueba"
          >
            <b-form-checkbox
              id="vi-modo-prueba"
              class="custom-control-primary"
              switch
              @change="onchangeAllBines"
              checked="promocion.AllBines"
              v-model="promocion.AllBines"
            />
          </b-form-group>
          <b-form-group
            v-if="!isPromocode && promocion.PrIdbanco && !promocion.AllBines && !promocion.AllBancos"
            label="Tarjeta"
            label-for="v-tarjeta"
          >
            <v-select
              id="v-tarjeta"
              label="title"
              v-model="promocion.PrIdtarjeta"
              :reduce="(t) => t.title"
              :options="tarjetas"
              :clearable="false"
              @option:selected="onchangeTarjeta"
              :class="[error.DatosGenerales.idTarjeta ? 'is-invalid' : '']"
            />
            <div v-if="error.DatosGenerales.idTarjeta" class="invalid-feedback">
              Seleccione una trajeta
            </div>
          </b-form-group>

          <b-form-group
            v-if="!isPromocode && promocion.PrIdtarjeta && !promocion.AllBancos"
            label="Bin"
            label-for="v-bin"
          >
            <v-select
              id="v-bin"
              label="biBin"
              v-model="promocion.Bines"
              :reduce="(b) => b.biIdbines"
              :options="mBines"
              multiple
              :class="[error.DatosGenerales.bines ? 'is-invalid' : '']"
            />
            <div v-if="error.DatosGenerales.bines" class="invalid-feedback">
              Seleccione Bin
            </div>
          </b-form-group>
        </tab-content>

        <tab-content title="Fecha de validez" :before-change="() => validateFechaValidez()">
          <h4>Fecha de validez</h4>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Fecha de Inicio" label-for="v-fecha-ini">
                <b-form-datepicker
                  id="v-fecha-ini"
                  :min="currentDate"
                  locale="es-EC"
                  v-model="promocion.PrFechaInicio"
                  :date-format-options="{
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }"
                 :class="[error.fechaValidez.fechaInicio ? 'is-invalid' : '']"
                />
                <div v-if="error.fechaValidez.fechaInicio" class="invalid-feedback">
                  Debe ingresar una fecha de inicio
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Hora de Inicio" label-for="v-hora-ini">
                <b-form-input
                  type="time"
                  id="v-hora-ini"
                  v-model="promocion.PrHoraInicio"
                  locale="en"
                  :class="[error.fechaValidez.HoraInicio ? 'is-invalid' : '']"
                />
                <div v-if="error.fechaValidez.HoraInicio" class="invalid-feedback">
                  Debe ingresar una hora de inicio
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Fecha de fin" label-for="v-fecha-fin">
                <b-form-datepicker
                  id="v-fecha-fin"
                  :min="promocion.PrFechaInicio"
                  :date-format-options="{
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }"
                  v-model="promocion.PrFechaFin"
                  locale="es-EC"
                  :class="[error.fechaValidez.fechaFin ? 'is-invalid' : '']"
                />
                <div v-if="error.fechaValidez.fechaFin" class="invalid-feedback">
                  Debe ingresar una fecha de finalización
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Hora de fin" label-for="v-hora-fin">
                <b-form-input
                  type="time"
                  id="v-hora-fin"
                  v-model="promocion.PrHoraFin"
                  locale="en"
                  :class="[error.fechaValidez.horaFin ? 'is-invalid' : '']"
                />
                <div v-if="error.fechaValidez.horaFin" class="invalid-feedback">
                  Debe ingresar una hora de finalización
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>

        <tab-content title="Tipo" :before-change="() => validateTipo()">
          <h4>Tipo</h4>

          <b-form-group>
            <b-form-radio-group
              id="v-tipo"
              buttons
              @change="onchangeTipo"
              v-model="promocion.PrTipoPromocion"
              :options="tipos"
              button-variant="outline-primary"
            />
          </b-form-group>

          <b-form-group
            v-if="promocion.PrTipoPromocion !== 6"
            :label="promocion.PrTipoPromocion === 3 ? 'Porcentaje' : 'Monto'"
            label-for="v-valor"
          >
            <b-input-group
              :prepend="promocion.PrTipoPromocion === 3 ? '%' : '$'"
            >
              <b-form-input
                id="v-valor"
                type="number"
                v-model="promocion.PrPorcentajeDescuento"
                :placeholder="
                  promocion.PrTipoPromocion === 3 ? 'Porcentaje' : 'Monto'
                "
                :class="[error.tipo.porcentajeDescuento ? 'is-invalid' : '']"
              />
              <div v-if="error.tipo.porcentajeDescuento" class="invalid-feedback">
                Ingrese descuento
              </div>
            </b-input-group>
          </b-form-group>
        </tab-content>

        <tab-content title="Cantidad de usos" :before-change="() => validateCantidadUsos()">
          <b-row>
            <b-col cols="12">
              <h4>Cantidad de usos</h4>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Cantidad máxima de usos"
                label-for="v-cant-max"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UsersIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="v-cant-max"
                    type="number"
                    step="1"
                    v-model="promocion.PrCantidadMaximaPromocion"
                    :formatter="(v) => `${parseInt(v)}`"
                    :class="[error.cantidadUsos.CantidadMaximaPromocion ? 'is-invalid' : '']"
                    placeholder="0 = ilimitado"
                  />
                  <div v-if="error.cantidadUsos.CantidadMaximaPromocion" class="invalid-feedback">
                    Ingrese descuento
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Cantidad máxima de uso por Cliente"
                label-for="v-cant-cliente"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="v-cant-cliente"
                    type="number"
                    step="1"
                    v-model="promocion.PrCantidadUsoUsuario"
                    :formatter="(v) => `${parseInt(v)}`"
                    :class="[error.cantidadUsos.CantidadUsoUsuario ? 'is-invalid' : '']"
                    placeholder="0 = ilimitado"
                  />
                  <div v-if="error.cantidadUsos.CantidadUsoUsuario" class="invalid-feedback">
                    Ingrese descuento
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label="Monto Mínimo Compra" label-for="v-valor-min">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="DollarSignIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="v-valor-min"
                    type="number"
                    step=".01"
                    v-model="promocion.PrMontoMinimoCompra"
                    :value="maskedValue(promocion, 'PrMontoMinimoCompra')"
                    :class="[error.cantidadUsos.MontoMinimoCompra ? 'is-invalid' : '']"
                    placeholder=""
                  />
                  <div v-if="error.cantidadUsos.MontoMinimoCompra" class="invalid-feedback">
                    Ingrese descuento
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Monto Máximo Compra" label-for="v-valor-max">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="DollarSignIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="v-valor-max"
                    type="number"
                    step=".01"
                    v-model="promocion.PrMontoMaximoCompra"
                    :value="maskedValue(promocion, 'PrMontoMaximoCompra')"
                    :class="[error.cantidadUsos.MontoMaximoCompra ? 'is-invalid' : '']"
                    placeholder=""
                  />
                  <div v-if="error.cantidadUsos.MontoMaximoCompra" class="invalid-feedback">
                    Ingrese descuento
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>

        <tab-content title="Condiciones" :before-change="() => validateCondiciones()">
          <h4>Condiciones</h4>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Comercio" label-for="v-comercio">
                <b-form-checkbox-group
                  id="v-comercio"
                  buttons
                  @change="onChangeComercio"
                  v-model="promocion.Comercios"
                  :options="comercios"
                  button-variant="outline-primary"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="showStores">
              <b-row>
                <!-- people group 1 -->
                <b-col md="6">
                  <h6>Tiendas disponibles</h6>

                  <!-- Search -->
                  <b-input-group class="input-group-merge mb-1">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" class="text-muted" />
                    </b-input-group-prepend>
                    <b-form-input
                      size="sm"
                      v-model="searchStore"
                      placeholder="Buscar..."
                      v-on:input="filteredStores"
                    />
                  </b-input-group>

                  <vue-perfect-scrollbar
                    :settings="perfectScrollbarSettings"
                    class="scrollable-container"
                  >
                    <!-- draggable -->
                    <draggable
                      :list="unAssigned"
                      group="people"
                      class="mb-1 cursor-move drgcnt"
                    >
                      <div v-for="(store, idx) in unAssigned" :key="store.id">
                        <b-badge class="mr-25 mb-25" variant="light-dark">
                          <small>{{ store.razonComercial }}</small>
                        </b-badge>
                      </div>
                    </draggable>
                  </vue-perfect-scrollbar>
                </b-col>

                <!-- people group 2 -->
                <b-col md="6">
                  <h6 class="text-primary">Tiendas seleccionadas</h6>

                  <!-- Search -->
                  <b-input-group class="input-group-merge mb-1">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" class="text-muted" />
                    </b-input-group-prepend>
                    <b-form-input
                      size="sm"
                      v-model="searchStoreSelected"
                      placeholder="Buscar..."
                      v-on:input="filteredStoresSelected"
                    />
                  </b-input-group>

                  <vue-perfect-scrollbar
                    :settings="perfectScrollbarSettings"
                    class="scrollable-container"
                  >
                    <!-- draggable -->
                    <draggable
                      :list="assigned"
                      group="people"
                      class="mb-1 cursor-move drgcnt"
                      item-key="id"
                      @change="onChangeAssigned"
                    >
                      <div v-for="(store, idx) in assigned" :key="store.id">
                        <b-badge class="mr-25 mb-25" variant="light-primary">
                          <small>{{ store.razonComercial }}</small>
                        </b-badge>
                      </div>
                    </draggable>
                  </vue-perfect-scrollbar>
                </b-col>
              </b-row>
            </b-col>

            <b-col v-if="showStores" cols="12" md="6" class="my-1">
              <b-button
                size="sm"
                block
                style="font-size: 10px"
                @click="unselectAllStores"
                variant="outline-secondary"
              >
                Quitar todo
              </b-button>
            </b-col>

            <b-col v-if="showStores" cols="12" md="6" class="my-1">
              <b-button
                size="sm"
                block
                style="font-size: 10px"
                @click="selectAllStores"
                variant="outline-primary"
              >
                Seleccionar todo
              </b-button>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Valido para" label-for="v-validez">
                <b-form-radio-group
                  id="v-validez"
                  buttons
                  v-model="promocion.PrClienteNuevo"
                  :options="validos"
                  button-variant="outline-primary"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="promocion.PrTipoPromocion !== 6">
              <b-form-group label="Free delivery" label-for="v-free-d">
                <b-form-checkbox
                  id="v-free-d"
                  class="custom-control-primary"
                  switch
                  v-model="promocion.PrEnvioGratis"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content title="Resumen">
          <h4 class="mb-2">
            {{ tipoPromo === "PROCO" ? "Promocode:" : "Promotarjeta:" }}
            <b-badge pill variant="light-primary">{{
              promocion.PrCodigo
            }}</b-badge>

            <b-badge
              v-if="promocion.PrTipoPromocion !== 6"
              pill
              class="ml-1"
              variant="light-dark"
              >{{ promocion.PrTipoPromocion === 3 ? "%" : "$" }}
              {{ promocion.PrPorcentajeDescuento }}</b-badge
            >
            <b-badge
              v-if="promocion.PrEnvioGratis || promocion.PrTipoPromocion === 6"
              pill
              class="ml-1"
              variant="light-info"
              >FREE DELIVERY</b-badge
            >
          </h4>

          <b-row>
            <b-col cols="12" md="6">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="TagIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Nombre de la promoción</p>
                  <p class="mhj">{{ promocion.PrNombre }}</p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12" md="6">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="FileTextIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Descripción</p>
                  <p class="mhj">{{ promocion.PrDescripcion }}</p>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="CalendarIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Fecha de Inicio</p>
                  <p class="mhj">
                    {{ formattedDate(promocion.PrFechaInicio) }}
                  </p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="CalendarIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Fecha de Fin</p>
                  <p class="mhj">
                    {{ formattedDate(promocion.PrFechaFin) }}
                  </p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="ClockIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Hora de Inicio</p>
                  <p class="mhj">
                    {{ formattedHour(promocion.PrHoraInicio) }}
                  </p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="ClockIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Hora de fin</p>
                  <p class="mhj">
                    {{ formattedHour(promocion.PrHoraFin) }}
                  </p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="UsersIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Cantidad Máxima de uso</p>
                  <p class="mhj">{{ promocion.PrCantidadMaximaPromocion }}</p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="UserIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Cantidad de uso por cliente</p>
                  <p class="mhj">{{ promocion.PrCantidadUsoUsuario }}</p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="DollarSignIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Monto mínimo de compra</p>
                  <p class="mhj">{{ promocion.PrMontoMinimoCompra }}</p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="DollarSignIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Monto máximo de compra</p>
                  <p class="mhj">{{ promocion.PrMontoMaximoCompra }}</p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="ShoppingCartIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Comercios</p>
                  <p class="mhj">
                    {{
                      promocion.Comercios.map((a) => nombreCatalogo(a)).join(
                        ","
                      )
                    }}
                  </p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="TargetIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Promoción valida para</p>
                  <p class="mhj">
                    {{
                      promocion.PrClienteNuevo
                        ? "Clientes nuevos"
                        : "Todos los clientes"
                    }}
                  </p>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col v-if="tipoPromo === 'PROTA'" cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="TagIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Banco</p>
                  <p class="mhj">{{ promocion.PrIdbanco }}</p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col v-if="tipoPromo === 'PROTA'" cols="12" md="6" class="mt-2">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="TagIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p class="mht">Tarjeta</p>
                  <p class="mhj">{{ promocion.PrIdtarjeta }}</p>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col v-if="tipoPromo === 'PROTA'" cols="12" class="mt-2">
              <app-collapse accordion type="border">
                <app-collapse-item class="store-cont" title="Bines" isVisible>
                  <b-badge
                    pill
                    :key="idx"
                    v-for="(bin, idx) in promocion.Bines"
                    variant="light-dark"
                    >{{ bin }}
                  </b-badge>
                </app-collapse-item>
              </app-collapse>
            </b-col>

            <b-col v-if="showStores" cols="12" class="mt-2">
              <app-collapse accordion type="border">
                <app-collapse-item class="store-cont" title="Tiendas">
                  <b-badge
                    pill
                    :key="store.idEmpresaAsociado"
                    v-for="store in assigned"
                    variant="light-dark"
                    >{{ store.razonComercial }}</b-badge
                  >
                </app-collapse-item>
              </app-collapse>
            </b-col>
          </b-row>

          <b-form-group>
            <b-form-checkbox
              class="mt-3 text-right"
              switch
              v-model="promocion.prActivo"
            >
              Activar promoción
            </b-form-checkbox>
          </b-form-group>
        </tab-content>
      </form-wizard>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BModal,
  BMedia,
  BAvatar,
  BButton,
  BSpinner,
  BMediaBody,
  BFormGroup,
  BFormInput,
  BMediaAside,
  BInputGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
  BFormTimepicker,
  BFormDatepicker,
  BInputGroupPrepend,
  BFormCheckboxGroup,
} from "bootstrap-vue";

import moment from "moment";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import flatPickr from "vue-flatpickr-component";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { getAuthToken } from "@/auth/utils";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import _ from "lodash";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BModal,
    BMedia,
    BAvatar,
    BButton,
    vSelect,
    BSpinner,
    draggable,
    flatPickr,
    FormWizard,
    TabContent,
    BMediaBody,
    BFormGroup,
    BFormInput,
    AppCollapse,
    BMediaAside,
    BInputGroup,
    BFormCheckbox,
    BListGroupItem,
    AppCollapseItem,
    BFormRadioGroup,
    BFormTimepicker,
    BFormDatepicker,
    BInputGroupPrepend,
    BFormCheckboxGroup,
    VuePerfectScrollbar,
    ToastificationContent,
  },
  props: {
    tipoPromo: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    const currentDate = new Date();
    return {
      show: false,
      bines: [],
      error: {
        DatosGenerales: {
          nombre: false,
          descripcion: false,
          codigo: false,
          idBanco: false,
          idTarjeta: false,
          bines: false
        },
        fechaValidez: {
          fechaInicio: false,
          HoraInicio: false,
          fechaFin: false,
          horaFin: false
        },
        tipo: {
          porcentajeDescuento: false
        },
        cantidadUsos: {
          CantidadMaximaPromocion: false,
          CantidadUsoUsuario: false,
          MontoMinimoCompra: false,
          MontoMaximoCompra: false
        },
        condicion: {
          assigned: false,
        }
      },
      tipos: [
        { text: "Porcentaje", value: 3 },
        { text: "Monto", value: 4 },
        { text: "Free delivery", value: 6 },
      ],
      stores: [],
      validos: [
        { text: "Todos los clientes", value: false },
        { text: "Clientes nuevos", value: true },
      ],
      loading: true,
      comercios: [
        {
          text: "Market",
          value: 1,
        },
        {
          text: "Foodie",
          value: 2,
        },
        {
          text: "Plus",
          value: 5,
        },
      ],
      promocion: {
        Bines: [],
        PrTipo: this.tipoPromo,
        Sectores: [],
        Bodegas: [],
        Locales: [],
        PrActivo: true,
        PrCodigo: null,
        PrNombre: "",
        Comercios: [],
        PrIdbanco: null,
        PrFechaFin: "",
        PrHoraFin: "",
        PrIdempresa: 2,
        PrIdtarjeta: null,
        PrIdpromocion: 0,
        PrFechaInicio: "",
        PrHoraInicio: "",
        PrDescripcion: "",
        PrEnvioGratis: false,
        PrClienteNuevo: false,
        PrTipoPromocion: 3,
        PrMontoMinimoCompra: "1.00",
        PrMontoMaximoCompra: "100.00",
        PrCantidadUsoUsuario: 1,
        PrPorcentajeDescuento: 0.0,
        PrCantidadMaximaPromocion: 100,
        AllBines: true,
        AllBancos: false,
      },
      storesPlus: [],
      searchStore: "",
      currentDate: currentDate,
      storesFoodie: [],
      localesPromocion: [],
      searchStoreSelected: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      bodegasOptions: [],
      sectoresOptions: [],
      assigned: [],
      assignedCopy: [],
      unAssigned: [],
    };
  },
  methods: {
    validateCondiciones() {
      this.error.condicion.assigned = false
      if (this.promocion.Comercios.some(num => num === 2 || num === 5))
        this.error.condicion.assigned = this.assigned.length === 0
      
      if (
        this.error.condicion.assigned
      )
        return false
      
      return true
    },
    validateCantidadUsos() {
      this.error.cantidadUsos.CantidadMaximaPromocion = this.promocion.PrCantidadMaximaPromocion === "" || this.promocion.PrCantidadMaximaPromocion === "NaN"
      this.error.cantidadUsos.CantidadUsoUsuario = this.promocion.PrCantidadUsoUsuario === "" || this.promocion.PrCantidadUsoUsuario === "NaN"
      this.error.cantidadUsos.MontoMinimoCompra = this.promocion.PrMontoMinimoCompra === "" || this.promocion.PrMontoMinimoCompra === "NaN"
      this.error.cantidadUsos.MontoMaximoCompra = this.promocion.PrMontoMaximoCompra === "" || this.promocion.PrMontoMaximoCompra === "NaN"

      if(
        this.error.cantidadUsos.CantidadMaximaPromocion ||
        this.error.cantidadUsos.CantidadUsoUsuario ||
        this.error.cantidadUsos.MontoMinimoCompra ||
        this.error.cantidadUsos.MontoMaximoCompra
      )
        return false
      
      return true
    },
    validateTipo() {
      this.error.tipo.porcentajeDescuento = false
      if( this.promocion.PrTipoPromocion !== 6 )
        this.error.tipo.porcentajeDescuento = this.promocion.PrPorcentajeDescuento === ""
      
      if(
        this.error.tipo.porcentajeDescuento
      )
        return false
        
      return true
    },
    validateFechaValidez() {
      this.error.fechaValidez.fechaInicio = this.promocion.PrFechaInicio.length === 0 
      this.error.fechaValidez.HoraInicio = this.promocion.PrHoraInicio.length === 0
      this.error.fechaValidez.fechaFin = this.promocion.PrFechaFin.length === 0
      this.error.fechaValidez.horaFin = this.promocion.PrHoraFin.length === 0
    
      if (
        this.error.fechaValidez.fechaInicio ||
        this.error.fechaValidez.HoraInicio ||
        this.error.fechaValidez.fechaFin ||
        this.error.fechaValidez.horaFin
      )
        return false
      
      return true
    },
    validateDatosGenerales() {
      this.error.DatosGenerales.nombre = this.promocion.PrNombre.length === 0
      this.error.DatosGenerales.descripcion = this.promocion.PrDescripcion.length === 0
      if ( this.isPromocode )
      {
        this.error.DatosGenerales.codigo = this.promocion.PrCodigo === null || this.promocion.PrCodigo === ""
      }
      else
      {
          this.error.DatosGenerales.idBanco = this.promocion.PrIdbanco === null 
          if ( !this.promocion.AllBines ) 
            this.error.DatosGenerales.idTarjeta = this.promocion.PrIdtarjeta === null 
          this.error.DatosGenerales.bines = this.promocion.Bines.length === 0
      } 

      if (
        this.error.DatosGenerales.nombre ||
        this.error.DatosGenerales.descripcion ||
        this.error.DatosGenerales.codigo ||
        this.error.DatosGenerales.idBanco ||
        this.error.DatosGenerales.idTarjeta ||
        this.error.DatosGenerales.bines
      ) 
        return false


      return true
    },
    onChangeAssigned(evt) {
      if (_.has(evt, "added")) {
        this.assignedCopy = [...this.assigned];
      }
      if (_.has(evt, "removed")) {
        const { element } = evt.removed;
        this.assignedCopy = [
          ..._.filter(
            this.assignedCopy,
            (ac) => ac.idEmpresaAsociado !== element.idEmpresaAsociado
          ),
        ];
      }
    },
    maskedValue(promocion, key) {
      var val;
      var masked;
      var returning_value;
      val = promocion[key];
      if (typeof val !== "undefined" && val !== "") {
        masked = this.maskValue(val);
        returning_value = parseFloat(masked).toFixed(2);
        promocion[key] = returning_value;
      }
      return masked;
    },
    maskValue(val) {
      if (typeof val !== "string") {
        val = val.toString();
      }
      if (isNaN(val)) return "0.00";
      val = val.replace(".", "");
      if (val.length == 1) {
        val = "0.0" + val;
      } else if (val.length == 2) {
        val = "0." + val;
      } else {
        val = +val.slice(0, -2) + "." + val.substr(-2);
      }
      return val;
    },
    customFocusout(e) {
      e.target.value = (Number(e.target.value) || 0).toFixed(2);
    },
    customKeypress(e) {
      const keyCode = e.keyCode ? e.keyCode : e.which;
      const value = e.target.value;
      const i = value.indexOf(".");

      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || i != -1)) {
        // 46 is dot
        // only allow numbers and one dot
        e.preventDefault();
      } else if (
        e.target.selectionStart == e.target.selectionEnd &&
        (i == -1 || e.target.selectionEnd > i) &&
        value.split(".")[1]?.length > 1
      ) {
        // restrict to 2 decimal places
        e.preventDefault();
      }
    },
    onchangeTipo(tipo) {
      if (tipo === 6) this.promocion.PrEnvioGratis = true;
      else this.promocion.PrEnvioGratis = false;
    },
    onchangeBanco() {
      this.promocion.AllBines = true;
      this.promocion.Bines = null;
      this.promocion.PrIdtarjeta = "TODOS LOS BINES";
      this.loadBinesForBank();
    },
    onchangeAllBines() {
      if (this.promocion.AllBines) {
        this.promocion.Bines = null;
        this.promocion.PrIdtarjeta = "TODOS LOS BINES";
        this.loadBinesForBank();
      } else {
        this.promocion.Bines = [];
        this.PrIdtarjeta = null;
      }
    },
    onchangeAllBancos() {
      if (this.promocion.AllBancos) {
        this.promocion.Bines = null;
        this.promocion.PrIdtarjeta = "TODOS LOS BINES";
        this.promocion.PrIdbanco = "TODOS LOS BANCOS";
        this.promocion.Bines = this.bines.map((b) => b.biIdbines);
        this.promocion.AllBines = true;
      } else {
        this.promocion.Bines = [];
        this.PrIdbanco = "";
        this.PrIdtarjeta = "";
      }
    },
    loadBinesForBank() {
      const sBines = [];
      this.bines.forEach((b) => {
        if (b.biBanco === this.promocion.PrIdbanco) {
          sBines.push(b.biIdbines);
        }
      });
      this.promocion.Bines = sBines;
    },
    onchangeTarjeta() {
      this.promocion.Bines = null;
    },
    onChangeComercio() {
      let hasPlus, hasFoodie;
      let storesAUX = [];
      this.assigned = [];
      this.promocion.Comercios.forEach((comercio) => {
        if (comercio === 2) {
          hasFoodie = true;
          storesAUX = [...this.storesFoodie];
          this.unAssigned = [...this.storesFoodie];
        } else if (comercio === 5) {
          hasPlus = true;
          storesAUX = [...storesAUX, ...this.storesPlus];
          this.unAssigned = [...storesAUX, ...this.storesFoodie];
        }
      });
      this.assigned = this.localesPromocion
        .filter((s) => {
          if (hasPlus && s.lineaDeNegocio === "OTROS_COMERCIOS") return true;
          if (hasFoodie && s.lineaDeNegocio === "EATS") return true;
          return false;
        })
        .sort((a, b) => {
          if (a.razonComercial > b.razonComercial) return 1;
          if (a.razonComercial < b.razonComercial) return -1;
          return 0;
        });
      this.stores = storesAUX
        .filter(
          (s) =>
            !this.localesPromocion.some(
              (l) => l.idEmpresaAsociado === s.idEmpresaAsociado
            )
        )
        .sort((a, b) => {
          if (a.razonComercial > b.razonComercial) return 1;
          if (a.razonComercial < b.razonComercial) return -1;
          return 0;
        });
    },
    onclickCrear(event) {
      event.preventDefault();
      this.show = true;
      if (this.stores.length === 0) {
        const promises = [];
        promises.push(this.loadStores());
        promises.push(this.loadBodegas());
        if (this.tipoPromo === "PROTA") promises.push(this.loadBines());
        Promise.all(promises)
          .then(() => {
            this.loading = false;
          })
          .catch((e) => {
            this.show = false;
            this.loading = false;
            this.error = e;
            console.error(e);
            this.$toast({
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrió un error (${e.message}).`,
                title: "Error",
                variant: "danger",
              },
              component: ToastificationContent,
            });
          });
      }
    },
    selectAllStores(event) {
      event.preventDefault();
      this.onChangeComercio();
      this.localesPromocion = this.stores;
      this.assigned = this.assignedCopy = this.stores;
      this.stores = [];
      this.unAssigned = [];
    },
    unselectAllStores(event) {
      event.preventDefault();
      this.localesPromocion = [];
      this.assigned = [];
      this.onChangeComercio();
    },
    formattedDate(originalDate) {
      console.log(originalDate)
      return moment(originalDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(originalDate, "HH:mm:ss").format("hh:mm a");
    },
    nombreCatalogo(codigo) {
      switch (codigo) {
        case 1:
          return "Market";
        case 2:
          return "Foodie";
        case 5:
          return "Plus";
        default:
          return "Catalogo no mapeado";
      }
    },
    loadStores() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/MiTiendaObtenerLocales",
            body: JSON.stringify({
              marca: "false",
              IDEmpresa: 2,
              tokenSesion: getAuthToken(),
              TipoLineaComercial: "ALL",
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.storesPlus = res.data.lisEmpresaAsociada.filter(
                (s) => s.lineaDeNegocio === "OTROS_COMERCIOS"
              );
              this.storesFoodie = res.data.lisEmpresaAsociada.filter(
                (s) => s.lineaDeNegocio === "EATS"
              );
              resolve();
            } else {
              reject(res.data.mensaje);
            }
          })
          .catch((e) => {
            reject(e.message);
          });
      });
    },
    loadBodegas() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/SectoresBodegas",
            body: JSON.stringify({
              otp: "V",
              empresa: 2,
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.bodegasOptions = res.data.lsbodegas
                ? res.data.lsbodegas
                : [];
              this.sectoresOptions = res.data.lssectores
                ? res.data.lssectores
                : [];
              resolve();
            } else {
              reject(res.data.mensaje);
            }
          })
          .catch((e) => {
            reject(e.message);
          });
      });
    },
    loadBines() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.UrlMiddlewareGET, {
            path: "/bine/obtenertodos",
            body: JSON.stringify({tokenSesion: getAuthToken()}),
          })
          .then((res) => {
            this.bines = res.data;
            resolve();
          })
          .catch((e) => {
            reject(e.message);
          });
      });
    },
    forceDecimal(num) {
      const value = `${num}`;
      value.replace(".", ",");
      return parseFloat(value).toFixed(2);
    },
    formSubmitted() {
      this.loading = true;
      const promocion = JSON.parse(JSON.stringify(this.promocion));
      promocion.PrMontoMinimoCompra = this.forceDecimal(
        promocion.PrMontoMinimoCompra
      );
      promocion.PrMontoMaximoCompra = this.forceDecimal(
        promocion.PrMontoMaximoCompra
      );
      promocion.PrPorcentajeDescuento = this.forceDecimal(
        promocion.PrPorcentajeDescuento
      );
      promocion.PrFechaFin = moment(promocion.PrFechaFin).format(
        "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ"
      );
      promocion.PrFechaInicio = moment(promocion.PrFechaInicio).format(
        "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ"
      );
      // promocion.Locales = this.localesPromocion.map((a) => a.idEmpresaAsociado);
      promocion.Locales = this.assigned.map((a) => a.idEmpresaAsociado);
      promocion.Bodegas =
        this.bodegasOptions.length > 0 && this.promocion.Comercios.includes(1)
          ? this.bodegasOptions.map((b) => b.id)
          : [];
      promocion.Sectores = this.sectoresOptions.map((s) => s.id - 28);
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/CrearActualizarPromocion",
          body: JSON.stringify({
            empresa: 2,
            tokenSesion: getAuthToken(),
            promocion: promocion,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.show = false;
            this.callback();
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se creo correctamente la promocion`,
                title: "Exito",
              },
              component: ToastificationContent,
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al crear la promocion (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filteredStores() {
      // Filtro para la busqueda
      this.unAssigned = [
        ..._.filter(this.stores, (ua) => {
          const { razonComercial } = ua;
          return _.lowerCase(razonComercial).includes(
            _.lowerCase(this.searchStore)
          );
        }),
      ];

      // Filtro para remover asigandos
      this.unAssigned = [
        ..._.filter(this.unAssigned, (ua) => {
          const { idEmpresaAsociado } = ua;
          return !_.some(this.assigned, { idEmpresaAsociado });
        }),
      ];
    },
    filteredStoresSelected() {
      // Filtro para la busqueda
      this.assigned = [
        ..._.filter(this.assignedCopy, (ua) => {
          const { razonComercial } = ua;
          return _.lowerCase(razonComercial).includes(
            _.lowerCase(this.searchStoreSelected)
          );
        }),
      ];
    },
  },
  computed: {
    bancos() {
      const bancos = [];
      this.bines.forEach((b) => {
        if (!bancos.some((bb) => bb.title === b.biBanco))
          bancos.push({ title: b.biBanco });
      });
      return bancos;
    },
    tarjetas() {
      const tarjetas = [];
      if (this.promocion.PrIdbanco)
        this.bines
          .filter((b) => b.biBanco === this.promocion.PrIdbanco)
          .forEach((b) => {
            if (!tarjetas.some((bb) => bb.title === b.biDescripcion))
              tarjetas.push({ title: b.biDescripcion });
          });
      return tarjetas;
    },
    mBines() {
      let bines = [];
      if (this.promocion.AllBines) {
        bines = this.bines;
      } else if (this.promocion.PrIdtarjeta)
        bines = this.bines.filter(
          (b) => b.biDescripcion === this.promocion.PrIdtarjeta
        );
      return bines;
    },
    isPromocode() {
      return this.tipoPromo === "PROCO";
    },
    modalTitle() {
      if (this.tipoPromo === "PROCO") return "Crear Promocode";
      return "Crear Promotarjeta";
    },
    showStores() {
      if (this.promocion.Comercios.length > 0)
        return this.promocion.Comercios.some((a) => a === 2 || a === 5);
      return false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.mhj {
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
}
.mht {
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 2px;
}
.slct {
  max-height: 100px;
}
.store-cont span {
  margin-right: 5px;
}
.drgcnt {
  height: 25vh;
}
.loader {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
